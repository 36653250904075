import React from 'react';
import { css, keyframes } from '@emotion/react';

const animation = keyframes`
0% {opacity:0; transform:translateY(10px)}
80% {opacity:1; transform:translateY(50px)}
100% {opacity:0; transform:translateY(50px)}
`;

const arrows = css`
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  bottom: 20px;

  & path {
    stroke: #ffffff;
    fill: transparent;
    stroke-width: 3px;
    animation: arrow 2s infinite;
    -webkit-animation: ${animation} 2s infinite;
  }
`;

const path = css`
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
`;

export const DownArrow = () => {
  return <> </>;
  return (
    <svg css={arrows}>
      <path stroke-linecap="round" css={path} d="M0 0 L20 22 L40 0"></path>
    </svg>
  );
};
