import { css } from '@emotion/react';
import React from 'react';
import { palette } from '../../../pallette';
import { mq } from '../../../utils/mq';
import { SectionHeader } from '../Header';
import { SectionWrapper } from '../../Wrapper';

const aboutStyle = css`
  font-family: 'Felix Titling', sans-serif;
  font-size: 30px;
  max-width: 70%;
  text-align: center;
`;

const smallerStyle = css`
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: normal;
  max-width: 70%;
  text-align: center;
`;

const quote = css`
  font-family: 'Nice Day', sans-serif;
  text-align: center;
  font-size: 15px;
  padding-vertical: 16px;

  ${mq[1]} {
    font-size: 16px;
  }
`;

export const About = () => {
  return (
    <SectionWrapper color={palette.complimentary.rgba(0.5)} id="about">
      <p css={aboutStyle}>Välkommen till Cloud Nine Clinic i Vasastan!</p>
      <p css={smallerStyle}>
        "On cloud nine" är ett engelskt talesätt som kan översättas till "på
        rosa moln" vilket är vårt mål för hur du ska känna dig efter ett besök
        hos oss.
      </p>

      <p css={smallerStyle}>
        Hos oss kan du boka ansiktsbehandlingar, peelingar, microneedling, IPL,
        lash- och browlift/färg, vaxning, massage och SPA-behandlingar.
      </p>

      <p css={quote}>When I left, I was on cloud nine.</p>

      <p css={smallerStyle}>
        Cloud Nine Clinic drivs av Emma och Angelica som är auktoriserade hud-
        och spaterapeuter och medlemmar i SHR.
      </p>

      <p css={smallerStyle}>
        Vår vision är att skapa en harmonisk plats för dig, din egentid,
        njutning och skönhetsvård. När du besöker oss på Cloud Nine Clinic vill
        vi att du ska hitta ditt inre lugn och prioritera dig själv, för det är
        du värd.
      </p>
    </SectionWrapper>
  );
};
