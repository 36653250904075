import { css, Theme } from '@emotion/react';
import React, { PropsWithChildren } from 'react';
import { palette } from '../../pallette';

interface MenuItemProps {
  onClick?: () => void;
  href?: string;
}

const buttonStyles = css`
  padding: 8px;
  color: #d6cdcb;
  font-size: 28px;
  cursor: pointer;
  font-family: Felix Titling, sans-serif;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: 0.1s ease-in-out scale;
  text-decoration: none;
  text-align: center;
  &:hover {
    scale: 1.1;
  }
`;

export const MenuItem = (props: PropsWithChildren<MenuItemProps>) => {
  return (
    <a
      href={props.href}
      onClick={props.onClick}
      target="_blank"
      css={[buttonStyles]}
    >
      {props.children}
    </a>
  );
};
