import { throws } from 'assert';

function hexToRgbA(hex: string, alpha: number) {
  var c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      alpha +
      ')'
    );
  }
  throw new Error('Bad Hex');
}

export const palette = {
  primary: {
    hex: '#9B7F79',
    rgba(alpha: number) {
      return hexToRgbA(this.hex, alpha);
    },
  },
  complimentary: {
    hex: '#B19B96',
    rgba(alpha: number) {
      return hexToRgbA(this.hex, alpha);
    },
  },
  secondary: {
    hex: '#6C5955',
    rgba(alpha: number) {
      return hexToRgbA(this.hex, alpha);
    },
  },
  tertiary: {
    hex: '#FFF3F1',
    rgba(alpha: number) {
      return hexToRgbA(this.hex, alpha);
    },
  },
  quaternary: {
    hex: '#FFE8E3',
    rgba(alpha: number) {
      return hexToRgbA(this.hex, alpha);
    },
  },
  white: {
    hex: '#FFFFFF',
    rgba(alpha: number) {
      return hexToRgbA(this.hex, alpha);
    },
  },
  black: {
    hex: '#000',
    rgba(alpha: number) {
      return hexToRgbA(this.hex, alpha);
    },
  },
};
