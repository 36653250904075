import { css } from '@emotion/react';
import React from 'react';
import { palette } from '../../pallette';

const headerStyle = css`
  font-family: 'Felix Titling', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 80px;
  font-weight: bold;
  padding: 0;
  margin: 0;
`;

const logoWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
`;

const middleStyle = css`
  font-size: 40px;
  margin: 0;
  padding: 0;
`;

export const Logo = ({
  size = 'large',
  color = palette.primary.hex,
}: {
  size?: 'small' | 'large';
  color?: string;
}) => {
  return (
    <div css={[logoWrapper, { color }]}>
      <p css={[headerStyle, { fontSize: size === 'small' ? 30 : 80 }]}>Cloud</p>
      <p css={[middleStyle, { fontSize: size === 'small' ? 16 : 40 }]}>Nine</p>
      <p css={[headerStyle, { fontSize: size === 'small' ? 30 : 80 }]}>
        Clinic
      </p>
    </div>
  );
};
