import { css } from '@emotion/react';
import React from 'react';
import { palette } from '../../../pallette';
import { SectionHeader } from '../Header';
import { SectionWrapper } from '../../Wrapper';
import { Container, Row, Col } from 'react-grid-system';
import { Profile } from './Profile';
import emma from './img/emma.png';
import angie from './img/angie.png';

const iframeStyles = css`
  border-radius: 10px;
  position: relative;
  display: block;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
`;

const container = css`
  gap: 8px;
`;
export const Contact = () => {
  return (
    <SectionWrapper color={palette.secondary.rgba(0.2)} id="contact">
      <SectionHeader>Kontakt</SectionHeader>
      <div style={{ width: '100%' }}>
        <Container>
          <Row>
            <Col xs={12} md={6} css={container}>
              <Profile
                image={emma}
                name="Emma Lindholm"
                number="076-248 76 77"
                title="Auktoriserad hud- och spaterapeut"
                email="emma@cloudnineclinic.se"
              />
              <Profile
                image={angie}
                name="Angelica Nylén"
                number="076-196 09 66"
                title="Auktoriserad hud- och spaterapeut"
                email="angelica@cloudnineclinic.se"
              />

              <div style={{ borderRadius: 8 }}>
                <a
                  href="https://www.exuviance.se/#clinic=2429&amp;link=8cea1d6f"
                  target="_top"
                >
                  <img
                    src="//hbsnordic.postaffiliatepro.com/accounts/default1/8gku45zzb/8cea1d6f.jpg"
                    alt="Köp Exuviance Professional produkter i salongens webbshop"
                    title="Köp Exuviance Professional produkter i salongens webbshop"
                    style={{ width: '100%' }}
                  />
                </a>
                <img
                  style={{ border: 0 }}
                  src="https://hbsnordic.postaffiliatepro.com/scripts/8gku45zzi?clinic=2429&amp;link=8cea1d6f"
                  width="1"
                  height="1"
                  alt=""
                />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <iframe
                css={iframeStyles}
                width="100%"
                height={500}
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=Cloud%20Nine%20Clinic%20Kungstensgatan%2059,%20113%2029%20Stockholm&t=&z=15&ie=UTF8&iwloc=&output=embed"
                frameBorder="0"
                scrolling="no"
                marginHeight={0}
                marginWidth={0}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </SectionWrapper>
  );
};
