import React from 'react';
import { SocialIcon } from 'react-social-icons';
import { css } from '@emotion/react';
import { mq } from '../../utils/mq';

const wrapper = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: Felix Titling, sans-serif;
  color: #d6cdcb;
  position: absolute;
  bottom: 0;

  ${mq[0]} {
    bottom: 10%;
  }

  & a,
  & p {
    display: inherit;
    color: inherit;
    text-decoration: inherit;
    margin: inherit;
    padding: inherit;
    padding: 4px;
    font-size: 12px;

    ${mq[0]} {
      font-size: 16px;
    }
  }
`;

export const SocialFooter = () => {
  return (
    <div css={wrapper}>
      <p>Kungstensgatan 59, Stockholm</p>
      <a href="mailto:info@cloudnineclinic.se">info@cloudnineclinic.se</a>
      <div>
        <SocialIcon
          bgColor="transparent"
          fgColor="#D6CDCB"
          url="https://www.instagram.com/cloudnine.clinic/"
        />
        <SocialIcon
          bgColor="transparent"
          fgColor="#D6CDCB"
          url="https://www.facebook.com/cloudnine.clinic/"
        />
      </div>
    </div>
  );
};
