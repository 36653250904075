import React, { PropsWithChildren, useEffect } from 'react';
import { css } from '@emotion/react';
import { useAnimation, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { mq } from '../../../utils/mq';

const headerStyle = css`
  font-family: 'Felix Titling', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 40px;
  font-weight: normal;
  padding: 32px;
  margin: 0;

  & ${mq[0]} {
    font-size: 80px;
  }
`;

const subheaderStyle = css`
  font-family: 'Felix Titling', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 40px;
  font-weight: bold;
  padding: 0;
  margin: 0;
`;

const squareVariants = {
  visible: { opacity: 1, scale: 1, transition: { duration: 1 } },
  hidden: { opacity: 0, scale: 0 },
};

export const SectionHeader = ({ children }: PropsWithChildren<{}>) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);
  return (
    <motion.h2
      ref={ref}
      animate={controls}
      initial="visible"
      variants={squareVariants}
      css={headerStyle}
    >
      {children}
    </motion.h2>
  );
};

export const SectionSubheader = ({ children }: PropsWithChildren<{}>) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);
  return (
    <motion.h3
      ref={ref}
      animate={controls}
      initial="visible"
      variants={squareVariants}
      css={subheaderStyle}
    >
      {children}
    </motion.h3>
  );
};
