import { css } from '@emotion/react';
import React from 'react';
import { PropsWithChildren } from 'react';
import { palette } from '../../../pallette';
import { mq } from '../../../utils/mq';

const profileWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px;
  background-color: ${palette.primary.rgba(0.1)};
  border-radius: 8px;
  align-items: center;
  gap: 18px;
  margin-bottom: 16px;

  & p,
  & a {
    margin: 0;
    padding: 2px;
    font-family: Roboto;
    color: ${palette.complimentary.hex};

    display: inherit;
    text-decoration: inherit;
  }

  & p:first-of-type {
    color: ${palette.primary.hex};
  }
`;

const image = css`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #fff, 0 0 10px 0 rgba(0, 0, 0, 0.2);
`;

export const Profile = (props: {
  name: string;
  title: string;
  image: string;
  number: string;
  email: string;
}) => {
  return (
    <div css={profileWrapper}>
      <div>
        <img css={image} src={props.image} />
      </div>
      <div>
        <p>{props.name}</p>
        <p>{props.title}</p>
        <a href={`tel:${props.number}`}>{props.number}</a>
        <a href={`mailto:${props.email}`}>{props.email}</a>
      </div>
    </div>
  );
};
