import { css } from '@emotion/react';
import React from 'react';
import montage from './montage.mp4';

import { DownArrow } from '../DownIcon';

const heroWrapper = css`
  display: flex;
  height: var(--unit-100vh);
  width: 100%;
  background-size: cover;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
`;

const videoElement = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

export const HeroVideo = () => {
  return (
    <div css={heroWrapper}>
      <video css={videoElement} playsInline autoPlay muted loop>
        <source src={montage} />
      </video>
      <DownArrow />
    </div>
  );
};
