import { css } from '@emotion/react';
import React, { PropsWithChildren } from 'react';
import { palette } from '../../pallette';

const mainWrapperStyles = css`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${palette.secondary.rgba(0.1)};
  color: ${palette.primary.hex};
  position: relative;
  min-height: var(--unit-100vh);
`;

const marker = css`
  position: absolute;
  top: -100px;
  display: block;
`;

const wrapper = css`
  position: relative;
`;

export const SectionWrapper = ({
  children,
  color,
  id,
}: PropsWithChildren<{ color?: string; id?: string }>) => {
  return (
    <div css={wrapper}>
      <div id={id} css={marker} />
      <section css={[mainWrapperStyles, { backgroundColor: color }]}>
        {children}
      </section>
    </div>
  );
};
