import * as React from 'react';
import type { HeadFC } from 'gatsby';
import { Header } from '../components/Header';
import { About } from '../components/sections/About';
import { Menu } from '../components/sections/Menu';
import { Contact } from '../components/sections/Contact';
import { HeroVideo } from '../components/HeroVideo';

import '../fonts/stylesheet.css';

const IndexPage = () => {
  return (
    <div css={{ position: 'relative' }}>
      <HeroVideo />
      <Header />
      <About />
      {/* <Menu /> */}
      <Contact />
    </div>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <>
    <meta name="viewport" content="width=device-width, user-scalable=no" />
    <title>Cloud Nine Clinic</title>
    <style>
      {`body {
        margin: 0;
        padding: 0;
        position: relative;
      }`}
    </style>
  </>
);
